import { styled } from '@mui/system'
import { Dialog } from '@mui/material'

export const DialogStyle = styled(Dialog)(({ theme }) => ({
  ' .MuiPaper-root': {
    textAlign: 'center',
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    border: '2px solid #202020',
    justifyContent: 'center',
    maxWidth: '348px !important',
    margin: '0',
    background: theme.palette.colors.chineseBlack,
    color: '#fff',
    width: '100%',
    [theme.breakpoints.between('sm', 'md')]: {},
    [theme.breakpoints.down('sm')]: {
      border: 'unset !important',
      borderRadius: 'unset !important',
      justifyContent: 'flex-end',
      maxWidth: '100vw !important',
      alignSelf: 'flex-end',
      margin: '0'
    }
    // '@media (min-width: 768px) and (max-width: 1024px)': {
    //   border: 'unset !important',
    //   borderRadius: 'unset !important',
    //   justifyContent: 'flex-end',
    //   maxWidth: '100vw !important',
    //   alignSelf: 'flex-end',
    //   margin: '0'
    // },
    // '@media (min-width: 1025px) and (max-width: 1366px)': {
    //   border: 'unset !important',
    //   borderRadius: 'unset !important',
    //   justifyContent: 'flex-end',
    //   maxWidth: '100vw !important',
    //   alignSelf: 'flex-end',
    //   margin: '0'
    // }
  },
  'css-tlc64q-MuiPaper-root-MuiDialog-paper': {
    [theme.breakpoints.between('sm', 'md')]: {},
    [theme.breakpoints.down('sm')]: {
      width: '100vw !important',
      maxWidth: '100vw !important'
    }
    // '@media (min-width: 768px) and (max-width: 1024px)': {
    //   width: '100vw !important',
    //   maxWidth: '100vw !important'
    // },
    // '@media (min-width: 1025px) and (max-width: 1366px)': {
    //   width: '100vw !important',
    //   maxWidth: '100vw !important'
    // }
  },

  '.MuiDialogContent-root': {
    width: '298px',
    padding: '0px',
    margin: '10px'
  },

  ' .MuiTypography-h6': {
    fontWeight: '600',
    fontSize: '17px',
    lineHeight: '22px'
  },
  ' .MuiButtonBase-root': {
    width: '100%'
  },
  '.MuiTypography-body1': {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#8A8A8A'
  },

  '.MuiDialogTitle-root': {
    fontWeight: '600',
    fontSize: '17px',
    padding: '0',
    margin: '0 auto 0 auto',
    display: 'block',
    justifySelf: 'flex-start',
    fontFamily: 'Prompt'
  },
  '.update-profile-desc': {
    paddingTop: '0',
    paddingBottom: '30px',
    fontSize: '14px',
    fontWeight: '500'
  },

  '.update-profile-title': {
    paddingTop: '33px'
  },

  label: {
    fontWeight: '600 !important',
    lineHeight: '18px',
    fontSize: '14px',
    marginTop: '2px',
    display: 'block',
    justifySelf: 'flex-start',
    paddingBottom: '2px',
    fontFamily: 'Prompt'
  },
  button: {
    '.MuiTypography-body1': {
      color: '#FFFFFF'
    }
  },

  '.MuiFormControl-root': {
    width: '100%'
  }
}))
