import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import { FC, useEffect } from 'react'
import { differenceInYears, isValid, parse } from 'date-fns'

import { getStorage } from '../../../utils/localStorageSupport'
import { useLazyGetUserProfileQuery } from '../../../store/services/user/userProfileApi'

interface SignInGTMEventsParams {
  event: string
  page_title?: string
  page_location?: string
  page_referrer?: string
  source?: string
  medium?: string
  campaign?: string
  user_id?: string
  event_category?: string
  event_action?: string
  event_label?: string
  signin_type?: string | undefined
  error_message?: string | undefined
  signin_typel?: string | undefined
  user_email?: string | undefined
  user_phone?: string | undefined
  user_gender?: string | undefined
  user_dob?: string | undefined
  svod_status?: string | undefined
  user_age?: string | number | undefined
  current_flow?: string | undefined
}

interface GTMEventTriggerProps {
  onSignUp?: any
  isSignInSuccessful?: boolean
  signInType?: string | undefined
  isSignInUnsuccessful?: boolean
  errorMessage?: string | undefined
  isSignInForgotPassword?: boolean
  isSignInSocial?: boolean
  isSignUpButton?: boolean
  isSignInContinueClicked?: any
  signInFailureMessage?: any
  isGMTOtpConfirmClicked?: boolean
  isForgotPasswordFailed?: boolean
  isPhoneDialogCreateAcccountClicked?: boolean
  isGTMResendOTPClicked?: boolean
  clearErrorsCb?: () => void
  isUserProfileUpdated?: boolean
}

const SignInGTMEvents: FC<GTMEventTriggerProps> = ({
  isSignInSuccessful,
  signInType,
  errorMessage,
  isSignInForgotPassword,
  isSignInSocial,
  isSignUpButton,
  isSignInContinueClicked,
  signInFailureMessage,
  isGMTOtpConfirmClicked,
  isForgotPasswordFailed,
  isPhoneDialogCreateAcccountClicked,
  clearErrorsCb,
  isUserProfileUpdated,
  isGTMResendOTPClicked
}) => {
  const { t } = useTranslation()

  const sha256 = async (message: string | undefined) => {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  }
  const storage = getStorage()
  const uid = storage.getItem('uid')
  const [getProfileData] = useLazyGetUserProfileQuery()

  const trackEvent = (params: SignInGTMEventsParams) =>
    TagManager.dataLayer({ dataLayer: { ...params } })

  const useTrackEvent = (
    condition: boolean | undefined,
    params: SignInGTMEventsParams,
    dependencies: any[] = []
  ) => {
    useEffect(() => {
      if (condition) {
        trackEvent(params)
        if (params.error_message && typeof clearErrorsCb === 'function') {
          clearErrorsCb()
        }
      }
    }, [condition, ...dependencies])
  }

  function parseDOB(dob: string): Date {
    const dateParsed = parse(dob, 'dd/MM/yyyy', new Date())
    if (isValid(dateParsed)) {
      return dateParsed
    }
    const isoDate = new Date(dob)
    return isoDate
  }
  function calculateAge(dob: string): number | string {
    if (!dob) return ''

    const birthDate = parseDOB(dob)

    if (!isValid(birthDate)) {
      return ''
    }
    return differenceInYears(new Date(), birthDate)
  }
  const formatDate = (date: Date | string | undefined): string => {
    if (!date || (typeof date === 'string' && date.trim() === '')) {
      return ''
    }

    if (typeof date === 'string') {
      const trimmedDate = date.trim()
      const ddmmyyyyPattern = /^\d{2}\/\d{2}\/\d{4}$/
      if (ddmmyyyyPattern.test(trimmedDate)) {
        return trimmedDate
      }
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date

    if (isNaN(dateObj.getTime())) {
      return ''
    }

    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1 // Months are zero-indexed
    const year = dateObj.getFullYear()

    const dd = day < 10 ? '0' + day : '' + day
    const mm = month < 10 ? '0' + month : '' + month

    return `${dd}/${mm}/${year}`
  }

  const signUp = () => {
    const currentLocation = window.location.href
    trackEvent({
      event: 'page_view',
      page_title: t('login.sign_in') || '',
      page_location: currentLocation,
      page_referrer: '',
      source: '',
      medium: '',
      campaign: ''
    })
  }

  useEffect(() => {
    signUp()
  }, [])

  useTrackEvent(isSignInContinueClicked, { event: 'signin_click_button' })
  // useTrackEvent(
  //   isSignInUnsuccessful,
  //   {
  //     event: 'signin_unsuccessful',
  //     event_category: 'signin',
  //     event_action: 'click_button',
  //     event_label: 'signin_unsuccessful',
  //     error_message: errorMessage
  //   },
  //   [errorMessage]
  // )

  useTrackEvent(
    signInFailureMessage,
    {
      event: 'signin_fail',
      error_message: signInFailureMessage,
      current_flow: 'signin'
    },
    [signInFailureMessage]
  )

  useTrackEvent(isGTMResendOTPClicked, {
    event: 'signin_resend_otp'
  })

  useTrackEvent(isGMTOtpConfirmClicked, { event: 'signin_confirm_otp' })

  useTrackEvent(isPhoneDialogCreateAcccountClicked, { event: 'create_account_button' })

  useTrackEvent(isSignInForgotPassword, {
    event: 'signin_forgot_password',
    event_category: 'signin',
    event_action: 'click_button',
    event_label: 'forgot_password'
  })

  useTrackEvent(isSignInForgotPassword, { event: 'forgot_password_click_button' })

  useTrackEvent(
    isForgotPasswordFailed,
    {
      event: 'forgot_password_fail',
      error_message: errorMessage,
      current_flow: 'forgot_password'
    },
    [errorMessage]
  )

  useTrackEvent(
    isSignInSocial,
    {
      event: `signin_select_${signInType}`,
      event_category: 'signin',
      event_action: 'click_button',
      event_label: signInType,
      signin_type: signInType
    },
    [signInType]
  )

  useTrackEvent(isSignUpButton, {
    event: 'signup_button',
    event_category: 'signin',
    event_action: 'click_button',
    event_label: 'signup'
  })

  useEffect(() => {
    const signInSuccessHandler = async () => {
      if (isSignInSuccessful) {
        const profile = await getProfileData()
        const userData = profile?.data
        const entitlement = userData?.entitlement
        const hashedEmail = await sha256(userData?.email)
        const hashedPhone = await sha256(userData?.phone)

        trackEvent({
          event: 'signin_successful',
          event_category: 'signin',
          event_action: 'click_button',
          event_label: 'signin_successful',
          signin_type: signInType,
          user_id: uid,
          user_email: userData?.email ? hashedEmail : undefined,
          user_phone: userData?.phone ? hashedPhone : undefined,
          user_dob: formatDate(userData?.date_of_birth),
          svod_status: entitlement?.subscription_status || 'no',
          user_gender: userData?.gender,
          user_age: calculateAge(userData?.date_of_birth as string)
        })
      }
    }
    signInSuccessHandler()
  }, [isSignInSuccessful, uid, signInType])

  useEffect(() => {
    const handleProfileUpdateSuccess = async () => {
      if (isUserProfileUpdated) {
        const profile = await getProfileData()
        const userData = profile?.data
        const entitlement = userData?.entitlement
        const hashedEmail = await sha256(userData?.email)
        const hashedPhone = await sha256(userData?.phone)

        trackEvent({
          event: 'update_account_info',
          user_id: uid,
          signin_type: signInType,
          user_email: userData?.email ? hashedEmail : undefined,
          user_phone: userData?.phone ? hashedPhone : undefined,
          user_dob: formatDate(userData?.date_of_birth),
          svod_status: entitlement?.subscription_status || 'no',
          user_gender: userData?.gender,
          user_age: calculateAge(userData?.date_of_birth as string)
        })
      }
    }
    handleProfileUpdateSuccess()
  }, [isUserProfileUpdated, uid, signInType])

  return null
}
export default SignInGTMEvents
