import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { isValid, subYears } from 'date-fns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'

import { setDayDisable, setIsMonthChange } from '../../../../store/slices/userProfileSlice'

import { DateElementStyle } from './DateDropdown.style'

type DateDropdownProps = {
  onDateChange?: (day: number | null, month: number | null, year: number | null) => void
  selectedCalendar?: any
  isLabel?: boolean
  icon?: any
}

const DateDropdown: FC<DateDropdownProps> = ({
  onDateChange,
  selectedCalendar,
  isLabel = false,
  icon
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const maxDate = subYears(new Date(), 18)
  const defaultDate = new Date(2000, 0, 1) // Month is 0-indexed

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    selectedCalendar ? new Date(selectedCalendar) : null
  )

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    if (date && isValid(date)) {
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      if (onDateChange) onDateChange(day, month + 1, year)
      dispatch(setDayDisable(false))
      dispatch(setIsMonthChange(true))
    } else {
      dispatch(setDayDisable(true))
    }
  }

  const getDateString = (date: Date | null) => {
    if (date && isValid(date)) {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }
    return ''
  }

  useEffect(() => {
    if (selectedDate && isValid(selectedDate) && onDateChange) {
      const day = selectedDate.getDate()
      const month = selectedDate.getMonth()
      const year = selectedDate.getFullYear()
      onDateChange(day, month + 1, year)
    }
  }, [selectedDate])

  const CustomCalendarIcon = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      fill='#F20B7E'
      width='20'
      height='20'
    >
      <path d='M6 0a1 1 0 00-1 1v1H4a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2h-1V1a1 1 0 10-2 0v1H7V1a1 1 0 00-1-1zM4 8h12v8H4V8z'></path>
      <rect x='6' y='9' width='2' height='2' fill='#F20B7E'></rect>
      <rect x='9' y='9' width='2' height='2' fill='#F20B7E'></rect>
      <rect x='12' y='9' width='2' height='2' fill='#F20B7E'></rect>
      <rect x='6' y='12' width='2' height='2' fill='#F20B7E'></rect>
      <rect x='9' y='12' width='2' height='2' fill='#F20B7E'></rect>
      <rect x='12' y='12' width='2' height='2' fill='#F20B7E'></rect>
    </svg>
  )

  return (
    <DateElementStyle className='form-field button_font_family'>
      {isLabel && (
        <label>
          {t('signup.date_of_birth') || ''} <span style={{ color: 'red' }}>&nbsp;*</span>
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          defaultCalendarMonth={defaultDate}
          value={selectedDate === null ? undefined : selectedDate}
          onChange={handleDateChange}
          maxDate={maxDate}
          sx={{
            backgroundColor: 'white',
            borderRadius: '10px'
          }}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                value={getDateString(selectedDate)}
                InputProps={{
                  ...params.InputProps,
                  placeholder: ''
                }}
              />
            ),
            openPickerIcon: icon || CustomCalendarIcon
          }}
        />
      </LocalizationProvider>
    </DateElementStyle>
  )
}

export default DateDropdown
