import { z } from 'zod'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { FieldValues, Path, RegisterOptions, useForm, UseFormRegister } from 'react-hook-form'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react' //useEffect
import Cookies from 'js-cookie'
import parse from 'html-react-parser'
import { ConfirmationResult, RecaptchaVerifier, UserCredential, UserProfile } from 'firebase/auth'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment
} from '@mui/material'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { zodResolver } from '@hookform/resolvers/zod'
import { Flex, Form, Label } from '@brightcove/studio-components'

import { LinkText } from '../LinkText/LinkText'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import InputField from '../Common/Input/Input'
import SignInGTMEvents from '../Common/GTM/SignInGTMEvents'
import { getStorage, isLocalStorageSupported } from '../../utils/localStorageSupport'
import { setEmailOrPhone, setPasswordOrOtp } from '../../store/slices/signupSlice'
import { forgotPasswordStart } from '../../store/slices/forgotPasswordSlice'
import { User } from '../../store/slices/config/types'
import { hideLogIn, setToken, setUser } from '../../store/slices/config/configSlice'
import {
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation
} from '../../store/services/user/userProfileApi'
import { useLazyGetUserCookieQuery } from '../../store/services/user/userCookieApi'
import {
  useLazyUserCheckEmailQuery,
  useLazyUserCheckPhoneQuery
} from '../../store/services/user/userCheckApi'
import { RootState } from '../../store'
import { usePersistentPopState } from '../../hooks/usePersistentPopState'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import useAuth from '../../hooks/useAuth'
import { useLocalStorage } from '../../hooks/UIHooks'
import { auth } from '../../firebase'
import { Image, SVGImage, GIFImage } from '../../assets/images'
import './Login.scss'

import UpdateProfileDialog from './UpdateProfileDialog/UpdateProfileDialog'
import OTPDialog from './OTPScreen/OTPDialog'
import ErrorDialog from './Components/ErrorDialog'

const loginFormSchema = ({ ...rest }) => {
  const mobileValidation = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
  if (rest.isMobileValidation) {
    return z.object({
      emailOrPhone: z.string().regex(mobileValidation, rest.emailError)
    })
  } else {
    return z.object({
      emailOrPhone: z.string().email(rest.emailError),
      passwordOrOtp: z.string().min(8, rest.passwordError)
    })
  }
}

interface errorState {
  isError: boolean
  message?: string
  errorMessage?: string
}

const { REACT_APP_REDEEM_REDIRECT_URL, REACT_APP_AIS_REDIRECT_URL } = process.env

const Login: FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [verificationId, setVerificationId] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [confirmObj, setConfirmObj] = useState<ConfirmationResult | null>(null)
  const [isMobileValidation, setIsMobileValidation] = useState(false)
  const { isMobile, isTablet } = useBreakPoints()
  const [, setCookiesUpdated] = useLocalStorage('cookies_updated')
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean | undefined>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPhoneDisabled, setIsPhoneDisabled] = useState<boolean | undefined>(false)
  const [isInvalidOtp, setIsInvalidOtp] = useState<boolean | undefined>(false)
  const [errorDialog, setErrorDialog] = useState<errorState>({ isError: false, message: '' })
  // const [isShowOTPTimer, setIsShowOTPTimer] = useState<boolean>(true)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isTimerCompleted, setIsTimerCompleted] = useState<boolean>(false)
  const [isSignInSuccessful, setisSignInSuccessful] = useState<boolean>(false)
  const [isGMTSignInSuccessful, setIsGMTSignInSuccessful] = useState<boolean>(false)
  const [isGMTOtpConfirmClicked, setIsGMTOtpConfirmClicked] = useState<boolean>(false)
  const [GTMSignInType, setGTMSignInType] = useState<string>('')
  const [isGTMSignInUnsuccessful, setIsGTMSignInUnsuccessful] = useState<boolean>(false)
  const [isGTMCreateAccountClicked, setIsGTMCreateAccountClicked] = useState<boolean>(false)
  const [isGTMResendOTPClicked, setIsGTMResendOTPClicked] = useState<boolean>(false)
  const [GTMErrorMessage, setGTMErrorMessage] = useState<any>()
  const [gtmSignInFailMessage, setGTMSignInFailMessage] = useState<any>()
  const [isGMTSignInForgotPassword, setIsGMTSignInForgotPassword] = useState<boolean>(false)
  const [isGTMSignInSocial, setIsGTMSignInSocial] = useState<boolean>(false)
  const [isSignInContinueClicked, setIsSignInContinueClicked] = useState<boolean>(false)
  const [isGTMSignUpButton, setIsGTMSignUpButton] = useState<boolean>(false)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { login, facebookLogin, googleLogin, appleLogin, loginWithPhone } = useAuth()
  const [getCookiePreference] = useLazyGetUserCookieQuery()
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [userCheckPhone]: any = useLazyUserCheckPhoneQuery()
  const [userCheckEmail]: any = useLazyUserCheckEmailQuery()
  const [isPhoneDialogOpen, setIsPhoneDialogOpen] = useState<boolean>(false)
  const [updateUserGender, setUpdateUserGender] = useState<boolean>(false)
  const [updateUserDOB, setUpdateUserDOB] = useState<boolean>(false)
  const [isUpdating, setIsProfileUpdating] = useState<boolean>(false)
  const [isUserProfileUpdated, setIsUserProfileUpdated] = useState<boolean>(false)
  const storage = getStorage()
  const [step, setStep] = useState(1)
  const [errorTranslation, setErrorTranslation] = useState<any>({})
  const emailOrPhoneVal = useSelector((state: RootState) => state.signup.emailOrPhone)
  const passwordOrOtpVal = useSelector((state: RootState) => state.signup.passwordOrOtp)
  const recaptchaVerifier = useRef<any>()
  const isRecaptchaCleared = useRef(false)

  useEffect(() => {
    if (!recaptchaVerifier.current) {
      recaptchaVerifier.current = new RecaptchaVerifier(
        'recaptcha-container',
        { size: 'invisible' },
        auth
      )
      isRecaptchaCleared.current = false // Reset flag on creation
    }

    return () => {
      if (recaptchaVerifier.current && !isRecaptchaCleared.current) {
        try {
          recaptchaVerifier.current.clear()
          isRecaptchaCleared.current = true
        } catch (error) {
          //errored
        }
        recaptchaVerifier.current = null
      }
    }
  }, [auth])

  useEffect(() => {
    if (location.state?.step && location.state?.step === 2) {
      setStep(2)
      location.state.step = null
    }
  }, [location])

  type FormValues = {
    emailOrPhone: string
    passwordOrOtp: string
  }

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
    setFocus
  } = useForm<FormValues>({
    resolver: zodResolver(
      loginFormSchema({
        emailError: 'login.invalid_email_tel',
        passwordError: 'login.invalid_password',
        isMobileValidation
      })
    ),
    mode: 'onSubmit',
    shouldUnregister: false,
    progressive: true
  })

  function safeRegister<TFieldValues extends FieldValues>(
    originalRegister: UseFormRegister<TFieldValues>
  ): UseFormRegister<TFieldValues> {
    return <TFieldName extends Path<TFieldValues>>(
      name: TFieldName,
      options?: RegisterOptions<TFieldValues, TFieldName>
    ) => {
      const registeredProps = originalRegister(name, options)

      return {
        ...registeredProps,
        onChange: async (...args: Parameters<typeof registeredProps.onChange>) => {
          try {
            const result = await registeredProps.onChange?.(...args)
            return result
          } catch (error) {
            // console.error('Error in onChange handler:', error)
          }
        },
        onBlur: async (...args: Parameters<typeof registeredProps.onBlur>) => {
          try {
            const result = await registeredProps.onBlur?.(...args)
            return result // Ensure the return value matches the original type
          } catch (error) {
            // console.error('Error in onBlur handler:', error)
          }
        }
      }
    }
  }
  const clearGTMErrors = () => {
    setGTMErrorMessage(null)
    setGTMSignInFailMessage(null)
    setIsSignInContinueClicked(false)
  }

  useEffect(() => {
    const translatedErrors = Object.entries(errors).reduce(
      (acc, [key, errorVal]) => {
        if (errorVal && typeof errorVal === 'object' && errorVal.message) {
          acc[key] = {
            ...errorVal,
            message: t(errorVal.message) // Translate the stored key
          }
        } else {
          acc[key] = errorVal
        }
        return acc
      },
      {} as typeof errors
    )
    setIsSignInContinueClicked(step === 2 && errors.passwordOrOtp !== undefined)
    setErrorTranslation(translatedErrors)
    if (errors.passwordOrOtp && errors.passwordOrOtp.type !== 'manual' && !otpSent) {
      setGTMSignInFailMessage(
        t(errors.passwordOrOtp.message || 'login.invalid_password', { lng: 'en' })
      )
    }
  }, [errors['emailOrPhone'], errors['passwordOrOtp'], t, i18n.language, setError])

  const emailOrPhone = watch('emailOrPhone', '')
  const passwordOrOtp = watch('passwordOrOtp', '')

  const isPasswordVisible = (): boolean | undefined => {
    if (emailOrPhone.length < 10) return true

    const hasNonNumeric = /[a-zA-Z@.]+/.test(emailOrPhone)
    return hasNonNumeric
  }

  const getUserCookiePreference = async () => {
    if (localStorage?.getItem('token')) {
      const data = await getCookiePreference()
      if (Object.keys(data?.['data']?.['cookies'] || {}).length > 0) {
        setCookiesUpdated(true)
        const element = document.getElementsByClassName('cookie_dialog_container')[0]
        if (element) {
          element.classList.add('hide_settings')
        }
        const settingsElement = document.getElementsByClassName('privacy_settings_holder')[0]
        if (settingsElement) {
          settingsElement.classList.add('hide_settings')
        }
      }
    }
  }

  const handleSuccessfulSignIn = () => {
    setisSignInSuccessful(true)
    setStep(3)
  }

  const navigatingLocation = () => {
    setIsGMTSignInSuccessful(true)
    getProfileData().then((profileResponse) => {
      const subscriptionStatus = profileResponse.data?.subscription?.subscription_status
      const spAccountId = profileResponse.data?.id

      if (location.state) {
        const { type, packageId } = location.state
        if (type === 'REDEEM') {
          window.location.href = `${REACT_APP_REDEEM_REDIRECT_URL}?spAccountId=${spAccountId}`
        } else if (type === 'ACTIVATE') {
          window.location.href = `${REACT_APP_AIS_REDIRECT_URL}?spAccountId=${spAccountId}&packageId=${packageId}`
        }
      } else {
        if (currentLocation?.includes('activation')) {
          navigate(currentLocation as string)
        } else {
          subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
        }
      }
    })

    // if (location.state?.aisContinue && !subscriptionStatus) {
    //   const packageId = location.state.packageId ?? ''
    //   navigate('/ais/activate', { state: { previousPath: location.pathname, packageId } })
    //   return
    // }
    // if (location.state?.previousPath !== '') {
    //   navigate(location.state?.previousPath)
    //   return
    // }
    // if (currentLocation?.includes('activation')) {
    //   navigate(currentLocation as string)
    // } else {
    //   subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
    // }
  }

  const handleResendOTP = async () => {
    setIsGTMResendOTPClicked(true)
    let taiPhoneNumber = ''
    if (emailOrPhone.startsWith('0')) {
      taiPhoneNumber = emailOrPhone.replace('0', '+66')
    }
    await recaptchaVerifier.current.verify()
    if (recaptchaVerifier.current?.destroyed === false) {
      const confirmationResult = await loginWithPhone(
        taiPhoneNumber || emailOrPhone,
        recaptchaVerifier.current
      )
      setVerificationId(confirmationResult.verificationId)
      setConfirmObj(confirmationResult)
      setOtpSent(false)
      setOtpSent(true)
      getUserCookiePreference()
    }
    setIsGTMResendOTPClicked(false)
  }

  const handleSignIn = async (data) => {
    setIsButtonDisabled(true)
    const { emailOrPhone, passwordOrOtp } = data
    checkIfEmailOrPhoneExistsAndNavigate()
    setPhoneNumber(emailOrPhone)
    try {
      if (isPasswordVisible()) {
        setIsSignInContinueClicked(true)
        const userCreds = await login(emailOrPhone, passwordOrOtp)
        if (userCreds.user) {
          dispatch(setUser(userCreds.user.toJSON() as User))
          const token = storage?.getItem('token')
          dispatch(setToken(token))
          getUserCookiePreference()
          !isLocalStorageSupported()
            ? sessionStorage?.setItem('shouldNavigateBack', 'true')
            : storage.setItem('shouldNavigateBack', 'true')
          setGTMSignInType('email')
          handleSuccessfulSignIn()
        }
      } else {
        let taiPhoneNumber = ''
        if (emailOrPhone.startsWith(0)) {
          taiPhoneNumber = emailOrPhone.replace(0, '+66')
        }
        setIsSignInContinueClicked(true)

        // Verifying the user if already signed in from phone number
        const response = await userCheckPhone(taiPhoneNumber || emailOrPhone)
        // Sign in with phone number and OTP
        if (!response.isSuccess) {
          setIsPhoneDialogOpen(true)
          return
        }
        await recaptchaVerifier.current.verify()
        if (recaptchaVerifier.current?.destroyed === false) {
          const confirmationResult = await loginWithPhone(
            taiPhoneNumber || emailOrPhone,
            recaptchaVerifier.current
          )
          setVerificationId(confirmationResult.verificationId)
          setConfirmObj(confirmationResult)
          setOtpSent(true)
          getUserCookiePreference()
        }
      }
    } catch (error: any) {
      setGTMErrorMessage('Invalid user/password')
      setIsButtonDisabled(false)
      if (error.code === 'auth/invalid-phone-number') {
        handleSignInFailure('emailOrPhone', {
          type: 'manual',
          message: 'signup.enter_valid_email_phone'
        })
      }
      if (error?.code === 'auth/user-not-found') {
        handleSignInFailure('emailOrPhone', {
          type: 'manual',
          message: 'login.invalid_email_tel'
        })
      }
      if (error?.code === 'auth/wrong-password') {
        handleSignInFailure('passwordOrOtp', {
          type: 'manual',
          message: 'login.invalid_password'
        })
      }
      if (error?.code === 'auth/too-many-requests') {
        setErrorDialog({
          isError: true,
          message: error?.message
        })
      }
      if (error?.code === 'auth/invalid-verification-code') {
        setErrorDialog({
          isError: false
        })
        setGTMErrorMessage('Invalid auth/invalid-verification-code')
        setIsInvalidOtp(true)
      }
      setIsGTMSignInUnsuccessful(true)
      setTimeout(() => {
        setIsGTMSignInUnsuccessful(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (emailOrPhoneVal && emailOrPhoneVal !== '') {
      setValue('emailOrPhone', emailOrPhoneVal)
    }
  }, [emailOrPhoneVal, step, setValue])

  useEffect(() => {
    if (step === 2 && passwordOrOtpVal !== '') {
      setValue('passwordOrOtp', passwordOrOtpVal)
    }
  }, [passwordOrOtpVal, step, setValue])

  const onPhoneDialogClose = () => {
    setIsPhoneDialogOpen(false)
    setIsGTMCreateAccountClicked(true)
    setIsButtonDisabled(false)
    setValue('emailOrPhone', '')
    setFocus('emailOrPhone')
    setStep(1)
  }

  useEffect(() => {
    if (!isPhoneDialogOpen) setFocus('emailOrPhone')
  }, [isPhoneDialogOpen])

  useEffect(() => {
    if (isInvalidOtp) {
      setGTMSignInFailMessage(t('signup.invalid_OTP', { lng: 'en' }))
    }
  }, [isInvalidOtp])

  const onIsTimerFinished = (isFinished: boolean) => setIsTimerCompleted(isFinished)

  const handleOTPSubmit = async (data): Promise<void> => {
    // setIsShowOTPTimer(false)
    setIsGMTOtpConfirmClicked(true)

    if (isTimerCompleted) return

    try {
      const { otp } = data
      if (otp === undefined || otp.length < 6) {
        setIsInvalidOtp(true)
        return
      } else {
        setIsInvalidOtp(false)
      }
      const currentUserDetail = await confirmObj?.confirm(otp)
      if (currentUserDetail?.user) {
        const token = await currentUserDetail.user.getIdToken()
        storage?.setItem('token', token)
        Cookies.set('authToken', token)
        setOtpSent(false)
        !isLocalStorageSupported()
          ? sessionStorage?.setItem('shouldNavigateBack', 'true')
          : storage.setItem('shouldNavigateBack', 'true')
        setGTMSignInType('phone')
        reset({
          emailOrPhone: '',
          passwordOrOtp: ''
        })
        handleSuccessfulSignIn()
      }
    } catch (error: any) {
      if (error?.code === 'auth/invalid-verification-code') {
        setErrorDialog({
          isError: false
        })
        setIsInvalidOtp(true)
      }
    }
  }

  // Event handler for checkbox change
  // const handleCheckboxChange = (event): void => {
  //   setIsChecked(event)
  // }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const hideLogInHandler = (event) => {
    const redirect = event?.target?.getAttribute('data-redirect')
    if (redirect.includes('password')) {
      setIsGMTSignInForgotPassword(true)
    }
    if (redirect.includes('signup')) {
      setIsGTMSignUpButton(true)
    }
    dispatch(hideLogIn())
    dispatch(forgotPasswordStart())
    dispatch(setEmailOrPhone(emailOrPhone))
    if (step == 2 && passwordOrOtp) dispatch(setPasswordOrOtp(passwordOrOtp))
    setTimeout(() => {
      navigate(redirect)
    }, 0)
  }

  type Providers = 'google' | 'facebook' | 'apple'

  const onSocialLogin = async (provider: Providers) => {
    try {
      let firebaseProvider: UserCredential | null = null
      switch (provider) {
        case 'google':
          setIsGTMSignInSocial(true)

          setGTMSignInType('google')
          firebaseProvider = await googleLogin()
          break
        case 'facebook':
          setIsGTMSignInSocial(true)

          setGTMSignInType('facebook')
          firebaseProvider = await facebookLogin()
          break
        case 'apple':
          setIsGTMSignInSocial(true)

          setGTMSignInType('apple')
          firebaseProvider = await appleLogin()
          break
        default:
          setIsGTMSignInSocial(false)

          throw new Error('Unsupported provider')
      }
      if (firebaseProvider) {
        const user = firebaseProvider.user
        if (user) {
          const displayName = user?.displayName
          const splitName = displayName?.split(' ')

          const data: UserProfile = {
            email: user?.email
              ? user?.email
              : firebaseProvider?.['_tokenResponse']?.['email']
                ? firebaseProvider?.['_tokenResponse']?.['email']
                : '',
            firstName: splitName?.length && splitName[0],
            lastName: splitName?.length && splitName?.slice(1).join(' '),
            phone: user.phoneNumber
          }
          await updateUserProfile(data)
          !isLocalStorageSupported()
            ? sessionStorage?.setItem('shouldNavigateBack', 'true')
            : storage.setItem('shouldNavigateBack', 'true')
          handleSuccessfulSignIn()
        }
      }
    } catch (error) {
      // Error handling
    }
  }

  useEffect(() => {
    if (!isPasswordVisible()) {
      setIsMobileValidation(true)
    } else {
      setIsMobileValidation(false)
    }
    const hasNonNumeric = /[a-zA-Z@.]+/.test(emailOrPhone)
    if (!hasNonNumeric && emailOrPhone && emailOrPhone.length < 10) {
      setIsPhoneDisabled(true)
    } else if (emailOrPhone && emailOrPhone.length >= 10) {
      setIsPhoneDisabled(false)
    }
    if (hasNonNumeric) setIsPhoneDisabled(false)
  }, [isPasswordVisible])

  const onDialogClose = () => {
    setOtpSent(false)
    setIsButtonDisabled(false)
  }

  // if (sessionStorage.getItem('shouldNavigateBack')) {
  //   navigate(currentLocation as string)
  // }

  const handleBackPressed = () => {
    if (step !== 1) {
      dispatch(setEmailOrPhone(emailOrPhone))
      setStep(1)
      reset({
        passwordOrOtp: ''
      })
    } else {
      dispatch(setPasswordOrOtp(''))
      dispatch(setEmailOrPhone(''))
      navigate(-1)
    }
  }

  const navigateToSignUp = () => {
    dispatch(setEmailOrPhone(emailOrPhone))
    navigate('/signup')
  }

  const checkIfEmailOrPhoneExistsAndNavigate = async () => {
    const isEmailExist = await userCheckEmail(emailOrPhone)
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/

    if (isEmailExist.status === 'fulfilled' || phoneRegex.test(emailOrPhone)) {
      clearErrors('emailOrPhone')
      setIsButtonDisabled(false)
      if (isEmailExist.status === 'fulfilled') {
        dispatch(setEmailOrPhone(emailOrPhone))
        setStep(2)
        navigate('/login?step=2')
      }
    } else {
      navigateToSignUp()
    }
  }
  const handlePopState = useCallback(() => {
    if (location && location.pathname !== '/login') return
    if (step && step === 2) {
      dispatch(setEmailOrPhone(emailOrPhone))
      setStep(1)
      reset({
        passwordOrOtp: ''
      })
    } else if (step && step === 1) {
      dispatch(setPasswordOrOtp(''))
      dispatch(setEmailOrPhone(''))
    }
  }, [step, location, setStep])
  usePersistentPopState(handlePopState)

  useEffect(() => {
    const checkUserData = async () => {
      const profileData = await getProfileData()
      const { date_of_birth, gender } = profileData.data || {}

      if (date_of_birth && gender) {
        navigatingLocation()
      } else {
        if (!date_of_birth) {
          setUpdateUserDOB(true)
        }
        if (!gender) {
          setUpdateUserGender(true)
        }
      }
    }
    if (isSignInSuccessful && step === 3) {
      setTimeout(() => checkUserData(), 3000)
    }
  }, [isSignInSuccessful, step])

  const onSubmitUserUpdate = (data) => {
    setIsProfileUpdating(true)
    updateUserProfile(data)
      .then(() => {
        setIsUserProfileUpdated(true)
        navigatingLocation()
        setIsProfileUpdating(true)
      })
      .catch(() => {
        setIsProfileUpdating(false)
      })
  }

  const handleSignInFailure = (error, errorObj) => {
    setGTMSignInFailMessage(t(errorObj.message, { lng: 'en' }))
    setError(error, errorObj)
  }

  const handleContinueClick = async () => {
    setIsButtonDisabled(true)
    setIsGTMCreateAccountClicked(false)
    const emailOrPhoneValue = emailOrPhone?.trim()
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/

    if (!emailOrPhoneValue) {
      // Show error for empty input
      handleSignInFailure('emailOrPhone', {
        type: 'required',
        message: 'login.invalid_email_tel'
      })
      setIsButtonDisabled(false)
      setError('emailOrPhone', {
        type: 'required',
        message: 'login.invalid_email_tel'
      })
      return
    }

    if (emailRegex.test(emailOrPhoneValue)) {
      checkIfEmailOrPhoneExistsAndNavigate()
    } else if (phoneRegex.test(emailOrPhoneValue)) {
      checkIfEmailOrPhoneExistsAndNavigate()
    } else {
      setIsButtonDisabled(false)
      // Specific error messages
      if (/\d/.test(emailOrPhoneValue)) {
        // Invalid or incomplete phone number
        handleSignInFailure('emailOrPhone', {
          type: 'invalid-phone',
          message: 'login.The_phone_number_is_incomplete'
        })
      } else {
        // Invalid email format
        handleSignInFailure('emailOrPhone', {
          type: 'invalid-email',
          message: 'login.invalid_email'
        })
      }
    }
  }

  return (
    <>
      {/* <RedirectBack auth={auth} /> */}
      <Flex
        className='login'
        height={'100%'}
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
      >
        {(isMobile || isTablet) && step !== 3 && (
          <div className='mobile-login-header'>
            <Flex>
              <ArrowBackIosIcon scale={4} />{' '}
              <div onClick={handleBackPressed}>{t('login.back')}</div>
            </Flex>
            <LanguageSelector />
          </div>
        )}
        <Flex
          className='login-wrapper'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          {step === 3 ? (
            <Flex alignItems='center' justifyContent='center' className=''>
              <Grid
                container
                direction='column'
                justifyContent='center'
                style={{ alignItems: 'center' }}
              >
                <Grid item sx={{ height: '20px' }}></Grid>

                <Grid item>
                  <img src={Image.OneDLogo} alt='OneD Logo' />
                </Grid>
                <Grid
                  item
                  sx={{
                    backgroundImage: `url(${GIFImage.SuccessTickGif})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '284px',
                    height: '347px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end'
                  }}
                >
                  <h3 className='confirm-header' style={{ paddingBottom: '30px' }}>
                    {t('login.login_completed') || ''}
                  </h3>
                </Grid>
                {/* <Grid item>
                </Grid> */}
                {/* <Grid item sx={{ height: '200px' }}></Grid> */}
              </Grid>
            </Flex>
          ) : (
            <>
              <NavLink className='' to='/'>
                <img className='login-logo' src={Image.One31Logo} alt='OneD logo' />
              </NavLink>
              <span className='login-header'>{parse(t('login.header'))}</span>
              <Flex>
                {step === 1 ? (
                  <img className='login-step-icon' src={SVGImage.StepOneIcon} alt='step one' />
                ) : (
                  <>
                    <Flex
                      justifyContent='center'
                      alignItems='center'
                      flexDirection='row'
                      style={{ position: 'relative', display: 'inline-block' }}
                    >
                      <img
                        src={GIFImage.SuccessTickGif}
                        style={{
                          position: 'absolute',
                          top: '45%',
                          left: '15%',
                          transform: 'translate(-50%, -50%)',
                          width: '82px',
                          height: '82px'
                        }}
                        alt='OneD Logo'
                      />
                      <img
                        className='login-step-icon'
                        style={{ position: 'relative', width: '100px' }}
                        src={SVGImage.steptwoIcon}
                        alt='step two'
                      />
                    </Flex>
                  </>
                )}
              </Flex>

              <Form
                className='login-form'
                onSubmit={
                  step === 2 || !isPasswordVisible()
                    ? handleSubmit(handleSignIn)
                    : handleContinueClick
                }
              >
                <div className='login-column-layout'>
                  <Label className='login-label'>
                    {step === 2
                      ? t('login.login_account')
                      : t('login.login_or_create_account') || ''}
                  </Label>
                  <InputField
                    name='emailOrPhone'
                    register={safeRegister(register)}
                    errors={errorTranslation}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    defaultValue={emailOrPhone || ''}
                    disabled={step === 2 && !!emailOrPhone}
                  />
                </div>

                {isPasswordVisible() && step === 2 && (
                  <div className='login-column-layout'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Label className='login-label'>{t('login.password_label') || ''}</Label>
                      <Link
                        className='login-forgot-password-link'
                        to=''
                        data-redirect='/password-reset'
                        onClick={hideLogInHandler}
                      >
                        {t('login.forgot_password')}
                      </Link>
                    </div>
                    <InputField
                      name='passwordOrOtp'
                      type={showPassword ? 'text' : 'password'}
                      register={register}
                      errors={!errors.emailOrPhone ? errorTranslation : undefined}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      defaultValue={passwordOrOtp || ''}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}

                {otpSent && (
                  <OTPDialog
                    handleOTPSubmit={handleOTPSubmit}
                    verificationId={verificationId}
                    isInvalidOtp={isInvalidOtp}
                    handleResendOtp={handleResendOTP}
                    // isShowOTPTimer={isShowOTPTimer}
                    isDialogOpen={otpSent}
                    onDialogClose={onDialogClose}
                    phoneNumber={phoneNumber}
                    isTimerFinished={onIsTimerFinished}
                    isTimerExpired={isTimerCompleted}
                  />
                )}

                <Button
                  sx={{
                    '&.Mui-disabled': {
                      opacity: 0.5,
                      backgroundColor: '#F20B7E',
                      color: 'white'
                    },
                    borderRadius: '12px',
                    backgroundColor: '#F20B7E',
                    fontSize: '18px',
                    color: 'white',
                    fontFamily: 'Prompt',
                    fontWeight: '500',
                    lineHeight: '23px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F20B7E'
                    },
                    padding: '16px 24px'
                  }}
                  type='submit'
                  variant='contained'
                  fullWidth
                  endIcon={
                    isButtonDisabled && <CircularProgress sx={{ color: '#fff' }} size={20} />
                  }
                  // disabled={
                  // !isSubmitDisabled ||
                  // isPhoneDisabled ||
                  // isButtonDisabled ||
                  // (isMobileValidation && isPasswordVisible())
                  // }
                >
                  {t('login.continue')}
                </Button>
              </Form>

              <Flex alignItems='center' justifyContent='center' className='login-separator'>
                <span style={{ color: '#8A8A8A' }}>{t('login.or_sign_in_with')}</span>
              </Flex>

              <Flex
                className={'login-with-social'}
                alignItems='center'
                justifyContent='center'
                flexDirection='row'
              >
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => onSocialLogin('facebook')}
                >
                  <img className='social-icon' src={Image.FacebookIcon} alt='Facebook Icon' />
                </Flex>
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => onSocialLogin('google')}
                >
                  <img className='social-icon' src={Image.GoogleIcon} alt='Google Icon' />
                </Flex>

                <Flex
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => onSocialLogin('apple')}
                >
                  <img className='social-icon' src={SVGImage.AppleIcon} alt='Apple Icon' />
                </Flex>
              </Flex>
              <Flex alignItems='center' justifyContent='center'>
                <div
                  className='login-terms-conditions'
                  key='terms_and_conditions'
                  // onChange={handleCheckboxChange}
                  // checked={isChecked}
                >
                  <Trans
                    i18nKey='login.policy_links'
                    components={{
                      link_terms: (
                        <LinkText
                          defaultText='ข้อกำหนดและเงื่อนไข'
                          linkKey='terms'
                          textKey='legal.terms'
                          className='login-link-text'
                        />
                      ),
                      link_privacy: (
                        <LinkText
                          defaultText='นโยบายความเป็นส่วนตัว'
                          linkKey='privacy'
                          textKey='legal.privacy'
                          className='login-link-text'
                        />
                      ),
                      link_notice: (
                        <LinkText
                          defaultText=''
                          linkKey='privacy'
                          textKey='legal.notice'
                          className='login-link-text'
                        />
                      )
                    }}
                  />
                </div>
              </Flex>
              {/* <div className='login-sign-up-link'>
            <span>{t('login.not_a_member')}?</span>
            <Link to='' data-redirect='/signup' onClick={hideLogInHandler}>
              {t('login.sign_up')}
            </Link>
          </div> */}
              <div id='recaptcha-container' style={{ display: 'none' }}></div>
            </>
          )}
        </Flex>
        {errorDialog.isError && (
          <ErrorDialog
            message={errorDialog.message}
            setClosed={() => {
              setIsButtonDisabled(false)
              setErrorDialog({ isError: false, message: '' })
            }}
          />
        )}
      </Flex>
      <SignInGTMEvents
        isSignInSuccessful={isGMTSignInSuccessful}
        isGTMResendOTPClicked={isGTMResendOTPClicked}
        clearErrorsCb={clearGTMErrors}
        isPhoneDialogCreateAcccountClicked={isGTMCreateAccountClicked}
        signInType={GTMSignInType}
        isSignInUnsuccessful={isGTMSignInUnsuccessful}
        signInFailureMessage={gtmSignInFailMessage}
        errorMessage={GTMErrorMessage}
        isSignInForgotPassword={isGMTSignInForgotPassword}
        isSignInSocial={isGTMSignInSocial}
        isSignUpButton={isGTMSignUpButton}
        isSignInContinueClicked={isSignInContinueClicked}
        isGMTOtpConfirmClicked={isGMTOtpConfirmClicked}
        isUserProfileUpdated={isUserProfileUpdated}
      />
      {/* Showing dialog box for existing user who registered with phone, but not subscribed  */}
      <Dialog
        open={isPhoneDialogOpen}
        PaperProps={{
          className: 'login_error_phone-number'
        }}
      >
        <DialogContent>
          <DialogTitle variant='h6'>{t('login.dialog.title')}</DialogTitle>
          <DialogTitle variant='body1'>{t('login.dialog.description')}</DialogTitle>
          <DialogActions>
            <Button onClick={onPhoneDialogClose}>{t('login.dialog.signup')}</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* showing dialog for user who has not entered the dob and gender */}
      <UpdateProfileDialog
        onUpdateProfile={onSubmitUserUpdate}
        updateUserGender={updateUserGender}
        updateUserDOB={updateUserDOB}
        isLoading={isUpdating}
      ></UpdateProfileDialog>
      <div id='recaptcha-container'></div>
    </>
  )
}

export default Login
