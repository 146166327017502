export type PopStateCallback = (event: PopStateEvent) => void

class PopStateSingleton {
  private static instance: PopStateSingleton | null = null

  private currentCallback: PopStateCallback | null = null

  private constructor() {
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', this.handlePopState)
    }
  }

  public static getInstance(): PopStateSingleton {
    if (!PopStateSingleton.instance) {
      PopStateSingleton.instance = new PopStateSingleton()
    }
    return PopStateSingleton.instance
  }

  private handlePopState = (event: PopStateEvent): void => {
    if (this.currentCallback) {
      this.currentCallback(event)
    }
  }

  public subscribe(callback: PopStateCallback): void {
    setTimeout(() => {
      this.unsubscribe()
      this.currentCallback = callback
    }, 300)
  }

  public unsubscribe(): void {
    this.currentCallback = null
  }
}

export default PopStateSingleton
