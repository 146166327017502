import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Flex } from '@brightcove/studio-components'

import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { setStep } from '../../store/slices/signupSlice'
import { RootState } from '../../store/index'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { GIFImage, Image, SVGImage } from '../../assets/images'

import StepThree from './StepThree'
import StepOne from './StepOne'

const SignUp: FC = () => {
  const { t } = useTranslation()
  const step = useSelector((state: RootState) => state.signup.step)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isMobile, isTablet } = useBreakPoints()

  const handleBackPressed = () => {
    if (step === 1) {
      navigate(-1)
    } else {
      dispatch(setStep(1))
    }
  }
  // if (sessionStorage.getItem('shouldNavigateBack')) {
  //   navigate(-1)
  // }

  return (
    <>
      <div className='signup-page'>
        {(isMobile || isTablet) && step !== 3 && (
          <div className='mobile-login-header'>
            <Flex>
              <ArrowBackIosIcon scale={4} />{' '}
              <div onClick={handleBackPressed}>{t('login.back')}</div>
            </Flex>
            <LanguageSelector />
          </div>
        )}
        {/* Logo and heading */}
        <NavLink to='/'>
          <img className='login-logo' src={Image.One31Logo} alt='OneD logo' />
        </NavLink>
        <span className='login-header'>{t('login.header')}</span>

        {step !== 3 && (
          <Flex
            justifyContent='center'
            alignItems='center'
            flexDirection='row'
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <img
              src={GIFImage.SuccessTickGif}
              style={{
                position: 'absolute',
                top: '45%',
                left: '15%',
                transform: 'translate(-50%, -50%)',
                width: '82px',
                height: '82px'
              }}
              alt='OneD Logo'
            />
            <img
              className='login-step-icon'
              style={{ position: 'relative', maxWidth: '100px' }}
              src={SVGImage.steptwoIcon}
              alt='step two'
            />
          </Flex>
        )}

        {step === 1 && <StepOne />}
        {/* {step === 2 && <StepTwo />} */}
        {step === 3 && <StepThree />}
      </div>
    </>
  )
}

export default SignUp
