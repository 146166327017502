import { useTranslation } from 'react-i18next'
import React, { FC, useState } from 'react'
import { Grid } from '@mui/material'

import { ButtonGroupElStyle, ButtonGroupStyle } from './GenderGroup.style'

interface GenderButtonGroupProps {
  onGenderChange?: (value: Gender) => void
  initialValue?: Gender
  selected?: string
  register?: () => void
  isLabel?: boolean
  includeLgbtq?: boolean
}

enum Gender {
  Male = 'Male',
  Female = 'Female',
  Lgbtq = 'LGBTQ+'
}

enum Gender_without_lgbtq {
  Male = 'Male',
  Female = 'Female',
  Others = 'Others'
}

const GenderGroup: FC<GenderButtonGroupProps> = ({
  onGenderChange,
  initialValue,
  isLabel = false,
  includeLgbtq
  // selected,
}) => {
  const { t } = useTranslation()
  const [selectedGender, setSelectedGender] = useState<Gender | Gender_without_lgbtq | null>(
    (initialValue as Gender) || null
  )

  const onGenderClick = (gender: Gender | null) => {
    const buttonGroup = document.querySelectorAll('.button-group') as NodeListOf<Element>
    buttonGroup.forEach((item) => item.classList.remove('active'))
    if (initialValue === gender?.toLowerCase()) {
      buttonGroup.forEach((item) => {
        if ((item as HTMLElement)?.innerText.toLocaleLowerCase() === initialValue?.toLowerCase()) {
          item.classList.add('active')
        }
      })
    }
    setSelectedGender(gender)
    if (gender && onGenderChange) {
      onGenderChange(gender)
    }
  }

  const genderOptions = includeLgbtq ? Object.values(Gender) : Object.values(Gender_without_lgbtq)

  return (
    <ButtonGroupElStyle
      container
      spacing={{ xs: 1, lg: 2, sm: 1 }}
      justifyContent='center'
      alignItems='center'
    >
      {/* Label spanning the full width */}
      <Grid item xs={12}>
        {isLabel ? (
          <label>
            {t('signup.gender')} <span style={{ color: 'red' }}>&nbsp;*</span>
          </label>
        ) : null}
      </Grid>

      {/* Gender buttons equally spaced */}
      <Grid item container spacing={2} justifyContent='flex-start'>
        {Object.values(genderOptions).map((gender) => (
          <Grid item xs={4} justifyContent='flex-end' key={gender}>
            <ButtonGroupStyle
              fullWidth
              onClick={() => onGenderClick(gender)}
              className={`button-group ${
                selectedGender?.toLowerCase() === gender.toLowerCase() ? 'active' : ''
              }`}
            >
              {t(`signup.${gender.replace(/\+/g, '').toLowerCase()}`)}
            </ButtonGroupStyle>
          </Grid>
        ))}
      </Grid>
    </ButtonGroupElStyle>
  )
}

export default GenderGroup
