import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import { FC, useEffect } from 'react'
import { differenceInYears, isValid, parse } from 'date-fns'

import { getStorage } from '../../../utils/localStorageSupport'
import { userSelector } from '../../../store/slices/config/configSelectors'
import { useLazyGetUserProfileQuery } from '../../../store/services/user/userProfileApi'
import { RootState } from '../../../store'

interface SignUpGTMEventsParams {
  event: string
  page_title?: string
  page_location?: string
  page_referrer?: string
  source?: string
  medium?: string
  campaign?: string
  user_id?: string
  event_category?: string
  event_action?: string
  event_label?: string
  error_message?: string | undefined
  signin_type?: string | undefined
  user_phone?: string | undefined
  user_email?: string | undefined
  user_gender?: string | undefined
  svod_status?: string | undefined
  user_dob?: string | undefined
  user_age?: string | number | undefined
}

interface GTMEventTriggerProps {
  onSignUp?: any
  isGTMError?: boolean
  errorMessage?: object | any
  isSignUpConfirmStep1?: boolean
  isSignUpConfirmStep1OPT?: boolean
  isSignUpConfirmStep1OTPFail?: boolean
  isSignUpConfirmStep2?: boolean
  isSignUpSuccessful?: boolean
  isUserEnteredDataContinue?: boolean
  enteredIncorrectPassword?: boolean
  isUserProfileUpdated?: boolean
  signInType?: string | undefined
}

const SignUpGTMEvents: FC<GTMEventTriggerProps> = ({
  isGTMError = false,
  errorMessage,
  isSignUpConfirmStep1,
  isSignUpConfirmStep1OPT,
  isSignUpConfirmStep1OTPFail,
  isSignUpConfirmStep2,
  isSignUpSuccessful,
  isUserEnteredDataContinue,
  enteredIncorrectPassword,
  isUserProfileUpdated,
  signInType
}) => {
  const { t } = useTranslation()
  const sha256 = async (message: string | undefined) => {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  }
  const [getProfileData] = useLazyGetUserProfileQuery()

  function parseDOB(dob: string): Date {
    const dateParsed = parse(dob, 'dd/MM/yyyy', new Date())
    if (isValid(dateParsed)) {
      return dateParsed
    }
    const isoDate = new Date(dob)
    return isoDate
  }
  function calculateAge(dob: string): number | string {
    if (!dob) return ''

    const birthDate = parseDOB(dob)

    if (!isValid(birthDate)) {
      return ''
    }
    return differenceInYears(new Date(), birthDate)
  }

  const formatDate = (date: Date | string | undefined): string => {
    if (!date || (typeof date === 'string' && date.trim() === '')) {
      return ''
    }

    if (typeof date === 'string') {
      const trimmedDate = date.trim()
      const ddmmyyyyPattern = /^\d{2}\/\d{2}\/\d{4}$/
      if (ddmmyyyyPattern.test(trimmedDate)) {
        return trimmedDate
      }
    }

    const dateObj = typeof date === 'string' ? new Date(date) : date

    if (isNaN(dateObj.getTime())) {
      return ''
    }

    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1 // Months are zero-indexed
    const year = dateObj.getFullYear()

    const dd = day < 10 ? '0' + day : '' + day
    const mm = month < 10 ? '0' + month : '' + month

    return `${dd}/${mm}/${year}`
  }

  const step = useSelector((state: RootState) => state.signup.step)
  const user = useSelector(userSelector)
  const storage = getStorage()
  const uid = storage.getItem('uid')

  const trackEvent = (params: SignUpGTMEventsParams) =>
    TagManager.dataLayer({ dataLayer: { ...params } })

  const useTrackEvent = (
    condition: boolean | undefined,
    params: SignUpGTMEventsParams,
    dependencies: any[] = []
  ) => {
    useEffect(() => {
      if (condition) {
        trackEvent(params)
      }
    }, [condition, ...dependencies])
  }

  const signUp = (step: number, user: any) => {
    const currentLocation = window.location.href
    trackEvent({
      event: 'page_view',
      page_title: t('signup.signup') || '',
      page_location: currentLocation,
      page_referrer: '',
      source: '',
      medium: '',
      campaign: '',
      user_id: step === 3 ? user?.uid : ''
    })
  }

  useEffect(() => {
    if (step === 1 || step === 3) {
      signUp(step, user)
    }
  }, [step, user])

  useTrackEvent(isUserEnteredDataContinue, {
    event: 'signup_confirm_info'
  })

  useTrackEvent(enteredIncorrectPassword, {
    event: 'signup_confirm_info_fail'
  })

  useTrackEvent(isSignUpConfirmStep1, {
    event: 'signup_confirm_step1',
    event_category: 'signup',
    event_action: 'click_button',
    event_label: 'confirm_step1'
  })

  useTrackEvent(isSignUpConfirmStep1OPT, {
    event: 'signup_confirm_step1_otp',
    event_category: 'signup',
    event_action: 'click_button',
    event_label: 'confirm_step1_otp'
  })

  useTrackEvent(isSignUpConfirmStep1OTPFail, {
    event: 'signup_confirm_step1_otp_fail',
    event_category: 'signup',
    event_action: 'click_button',
    event_label: 'confirm_step1_otp_fail'
  })

  useTrackEvent(isSignUpConfirmStep2, {
    event: 'signup_confirm_step2',
    event_category: 'signup',
    event_action: 'click_button',
    event_label: 'confirm_step2'
  })

  useEffect(() => {
    if (isGTMError) {
      const { emailOrPhone, passwordOrOtp } = errorMessage
      const error_message = `${emailOrPhone?.message || ''} ${passwordOrOtp?.message || ''}`.trim()
      if (error_message) {
        trackEvent({
          event: 'signup_confirm_info_fail',
          event_category: 'signup',
          event_action: 'click_button',
          event_label: 'signup_confirm_info_fail',
          error_message
        })
      }
    }
  }, [isGTMError, errorMessage])

  useEffect(() => {
    const handleSignUpSuccess = async () => {
      if (isSignUpSuccessful) {
        const profile = await getProfileData()
        const userData = profile?.data
        const entitlement = userData?.entitlement
        const hashedEmail = await sha256(userData?.email)
        const hashedPhone = await sha256(userData?.phone)

        trackEvent({
          event: 'signup_successful',
          event_category: 'signup',
          event_action: 'signup_successful',
          event_label: 'signup_successful',
          user_id: uid,
          signin_type: 'email',
          user_email: userData?.email ? hashedEmail : undefined,
          user_phone: userData?.phone ? hashedPhone : undefined,
          user_dob: formatDate(userData?.date_of_birth),
          svod_status: entitlement?.subscription_status || 'no',
          user_gender: userData?.gender,
          user_age: calculateAge(userData?.date_of_birth as string)
        })
      }
    }
    handleSignUpSuccess()
  }, [isSignUpSuccessful, uid])

  useEffect(() => {
    const handleProfileUpdateSuccess = async () => {
      if (isUserProfileUpdated) {
        const profile = await getProfileData()
        const userData = profile?.data
        const entitlement = userData?.entitlement
        const hashedEmail = await sha256(userData?.email)
        const hashedPhone = await sha256(userData?.phone)

        trackEvent({
          event: 'update_account_info',
          user_id: uid,
          signin_type: signInType,
          user_email: hashedEmail,
          user_phone: hashedPhone,
          user_dob: formatDate(userData?.date_of_birth),
          svod_status: entitlement?.subscription_status || 'no',
          user_gender: userData?.gender,
          user_age: calculateAge(userData?.date_of_birth as string)
        })
      }
    }
    handleProfileUpdateSuccess()
  }, [isUserProfileUpdated, uid, signInType])
  return null
}
export default SignUpGTMEvents
