import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material'
import { Form } from '@brightcove/studio-components'

import DateDropdown from '../../SignUp/Components/DateDropdown/DateDropdown'
import GenderGroup from '../../Common/GenderGroup/GenderGroup'

import { DialogStyle } from './UpdateProfileDialog.style'

interface UpdateProfileDialogProps {
  updateUserGender?: boolean
  updateUserDOB?: boolean
  onDialogClose?: () => void
  onUpdateProfile: (data) => void
  isLoading: boolean
}

const UpdateProfileDialog: FC<UpdateProfileDialogProps> = ({
  updateUserGender = false,
  updateUserDOB = false,
  onUpdateProfile,
  isLoading
}) => {
  const { t } = useTranslation()
  const [dateOfBirth, setDateOfBirth] = useState<Date | any>(null)
  const [gender, setGender] = useState<any>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const onSubmit = () => {
    onUpdateProfile({
      ...(dateOfBirth ? { date_of_birth: dateOfBirth } : {}),
      ...(gender ? { gender } : {})
    })
  }
  useEffect(() => {
    const requiresDOB = updateUserDOB ? dateOfBirth !== null : true
    const requiresGender = updateUserGender ? gender !== null : true
    setIsDisabled(!(requiresDOB && requiresGender))
  }, [dateOfBirth, gender, updateUserDOB, updateUserGender])

  const onDateChange = (day: number | null, month: number | null, year: number | null) => {
    if (day && month && year) {
      setIsDisabled(false)
      setDateOfBirth(new Date(year, month - 1, day))
    } else {
      setIsDisabled(true)
      setDateOfBirth(null)
    }
  }

  const onGenderChange = (gen) => {
    setIsDisabled(!gen || gen === gender)
    setGender(gen)
  }

  return (
    <DialogStyle
      className='update-profile-dialog'
      fullWidth
      autoFocus
      open={updateUserGender || updateUserDOB}
    >
      <DialogContent>
        <DialogTitle className={'update-profile-title'} variant='h6'>
          {t('login.update_profile_dialog.title')}
        </DialogTitle>
        <DialogTitle className={'update-profile-desc'} variant='body1'>
          {t('login.update_profile_dialog.description')}
        </DialogTitle>
        <Form onSubmit={onSubmit}>
          <Grid container xs={12} direction={'column'} className='uodate-profile-form'>
            {/* <Grid item xs={12}> */}
            <Grid direction={'column'} container>
              {updateUserDOB && (
                <Grid item xs={12} sx={{}}>
                  <DateDropdown onDateChange={onDateChange} isLabel />
                </Grid>
              )}
              {updateUserGender && (
                <Grid item container xs={12} style={{ marginBottom: 20, marginTop: 14 }}>
                  <GenderGroup onGenderChange={onGenderChange} includeLgbtq isLabel />
                </Grid>
              )}
              <Grid item container xs={12} style={{ marginBottom: 20 }}>
                {/* <DialogActions> */}
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: '#8B8B8B',
                      color: 'white'
                    },
                    backgroundColor: '#F20B7E'
                  }}
                  disabled={isDisabled}
                  endIcon={isLoading && <CircularProgress sx={{ color: '#fff' }} size={20} />}
                >
                  <Typography className='button_font_family'>
                    {t('signup.confirm') || ''}
                  </Typography>
                </Button>{' '}
                {/* </DialogActions> */}
              </Grid>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Form>
      </DialogContent>
    </DialogStyle>
  )
}

export default UpdateProfileDialog
