import { styled } from '@mui/system'
import { FormControl } from '@mui/material'

export const DateElementStyle = styled('div')(({ theme }) => ({
  flex: 1,

  '& .MuiFormControl-root': {

    width: '100%',
    button: {
      width: '36px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-root': {
      // width: '405px'
    },
    marginBlock: 15,
    label: {
      display: 'block'
    }
  }
}))

export const SelectStyle = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: theme.palette.colors.lightGray,
    height: 50,
    borderRadius: 10
  },
  '& .MuiSvgIcon-root': {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: 5
  }
}))
