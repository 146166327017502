import { z } from 'zod'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { isValid, parse } from 'date-fns'
import { styled } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '@brightcove/studio-components'

import ErrorDialog from '../Login/Components/ErrorDialog'
import { LinkText } from '../LinkText/LinkText'
import InputField from '../Common/Input/Input'
import GenderGroup from '../Common/GenderGroup/GenderGroup'
import SignUpGTMEvents from '../Common/GTM/SignUpGTMEvents'
import { getStorage } from '../../utils/localStorageSupport'
import {
  setBirthdate,
  setEmailOrPhone,
  setGender,
  setPasswordOrOtp,
  setStep
} from '../../store/slices/signupSlice'
import { useUpdateUserProfileMutation } from '../../store/services/signup/signupApi'
import { RootState } from '../../store'
import useAuth from '../../hooks/useAuth'
import { useCheckEmailExists } from '../../hooks/UIHooks'

import { FormFieldsStepOne } from './types'
import './Signup.scss'
import DateDropdown from './Components/DateDropdown/DateDropdown'

const registerFormSchema = ({ ...rest }) =>
  z.object({
    emailOrPhone: z.string().email(rest.emailError),
    passwordOrOtp: z.string().min(8, rest.passwordError)
  })

interface errorState {
  isError: boolean
  message?: string
  errorMessage?: string
}

const StepOneStyle = styled(Grid)(({ theme }) => ({
  ' .MuiInputBase-root': {
    // width: 405
  },
  [theme.breakpoints.down('sm')]: {
    ' .MuiInputBase-root': {
      // width: 348
    }
  }
}))

const StepOne: FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isChecked, setIsChecked] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorDialog, setErrorDialog] = useState<errorState>({ isError: false, message: '' })

  const { t, i18n } = useTranslation()
  const [errorTranslation, setErrorTranslation] = useState<any>({})
  const [errorEnglish, setErrorEnglish] = useState<any>({})

  const emailOrPhoneVal = useSelector((state: RootState) => state.signup.emailOrPhone)
  const passwordOrOtpVal = useSelector((state: RootState) => state.signup.passwordOrOtp)
  const [isGTMError, setIsGTMError] = useState<boolean>(false)
  const [GTMErrorMessage, setGTMErrorMessage] = useState<any>()
  const [isGTMSignUpConfirmStep1, setIsGTMSignUpConfirmStep1] = useState<boolean>(false)
  const [GTMSignInType, setGTMSignInType] = useState<string>('')

  const [dateOfBirth, setDateOfBirth] = useState<Date | any>(null)
  const [gender, setLocalGender] = useState<any>(null)
  const [validInput, setValidInput] = useState<any>({})
  const [isDisabled, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUserEnteredDataContinue, setIsUserEnteredDataContinue] = useState<boolean>(false)
  const [enteredIncorrectPassword, setEnteredIncorrectPassword] = useState<boolean>(false)
  const [isUserProfileUpdated, setIsUserProfileUpdated] = useState<boolean>(false)

  const [updateUserProfile] = useUpdateUserProfileMutation()
  const { signup } = useAuth()

  useState<boolean>(false)

  const validationSchema = registerFormSchema({
    emailError: 'signup.enter_valid_email_phone',
    passwordError: 'signup.passwords_8_characters',
    isSubmitted
  })

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm<FormFieldsStepOne>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      emailOrPhone: emailOrPhoneVal || ''
    },
    mode: 'onSubmit'
  })
  const storage = getStorage()

  const dispatch = useDispatch()

  useEffect(() => {
    const isValidInputCheck = !!(
      Object.values(validInput).every((val) => val !== '') &&
      (Object.keys(validInput).length === 3 || Object.keys(validInput).length === 4) &&
      (emailOrPhoneVal || Object.keys(validInput).length === 4)
    )
    setIsDisabled(isValidInputCheck)
  }, [setIsDisabled, validInput])

  useEffect(() => {
    const translatedErrors = Object.entries(errors).reduce(
      (acc, [key, errorVal]) => {
        if (errorVal && typeof errorVal === 'object' && errorVal.message) {
          acc[key] = {
            ...errorVal,
            message: t(errorVal.message)
          }
        } else {
          acc[key] = errorVal
        }
        return acc
      },
      {} as typeof errors
    )
    setErrorTranslation(translatedErrors)
  }, [errors['emailOrPhone'], errors['passwordOrOtp'], t, i18n.language])

  useEffect(() => {
    if (dateOfBirth !== null) setValue('dateOfBirth', dateOfBirth.toDateString())
  }, [dateOfBirth])

  useEffect(() => {
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const { checkEmail } = useCheckEmailExists()

  const onSubmit = async (data: FormFieldsStepOne) => {
    setIsLoading(true)
    setIsDisabled(true)

    const { emailOrPhone, passwordOrOtp } = data
    const isEmailExist = await checkEmail(emailOrPhone)

    if (isEmailExist) {
      setIsGTMError(true)
      setGTMErrorMessage(
        isEmailExist
          ? { emailOrPhone: { message: t('signup.email_exist', { lng: 'en' }) as string } }
          : { emailOrPhone: { message: t('signup.phone_exist', { lng: 'en' }) as string } }
      )
      setError('emailOrPhone', {
        type: 'manual',
        message: isEmailExist ? 'signup.email_exist' : 'signup.phone_exist'
      })
      setIsDisabled(false)
      setIsSubmitted(false)
      return
    } else {
      setIsGTMError(false)
    }
    if (passwordOrOtp.length <= 8) {
      setEnteredIncorrectPassword(true)
    } else {
      setEnteredIncorrectPassword(false)
    }

    try {
      dispatch(setEmailOrPhone(emailOrPhone))
      dispatch(setPasswordOrOtp(passwordOrOtp))
      setIsGTMSignUpConfirmStep1(true)
      setIsGTMSignUpConfirmStep1(false)
      setIsUserEnteredDataContinue(true)
      dispatch(setGender(gender))
      dispatch(setBirthdate(dateOfBirth))

      const isPhoneNumber = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/
      const authToken = Cookies.get('authToken')

      const newObj = {
        date_of_birth: dateOfBirth,
        gender,
        email: emailOrPhone ? emailOrPhone : undefined,
        phone: isPhoneNumber.test(emailOrPhone) ? emailOrPhone : undefined
      }

      if (isPhoneNumber.test(emailOrPhone)) {
        setGTMSignInType('phone')
        const taiPhoneNumber = emailOrPhone.startsWith('0')
          ? emailOrPhone.replace('0', '+66')
          : emailOrPhone
        newObj.phone = taiPhoneNumber
        delete newObj.email
        if (authToken) {
          const signupResponse = await updateUserProfile(newObj)
          if (signupResponse) {
            setIsUserProfileUpdated(true)
            setIsGTMSignUpConfirmStep1(true)
            setIsUserEnteredDataContinue(false)
            setTimeout(() => {
              dispatch(setStep(3))
            }, 0)
          }
        }
        return
      } else {
        delete newObj.phone
      }
      setGTMSignInType('email')

      const signupResponse = await signup(emailOrPhone, passwordOrOtp)
      const currentUserDetail = signupResponse.user
      if (currentUserDetail) {
        const token = await currentUserDetail.getIdToken()
        storage?.setItem('token', token)
        Cookies.set('authToken', token)
        if (token) {
          const signupResponse = await updateUserProfile(newObj)
          if (signupResponse) {
            setIsUserProfileUpdated(true)
            setIsGTMSignUpConfirmStep1(true)
            setIsUserEnteredDataContinue(false)

            setTimeout(() => {
              dispatch(setStep(3))
            }, 0)
          }
        }
      }
    } catch (error: any) {
      setIsUserEnteredDataContinue(false)

      setIsLoading(false)
      setIsDisabled(false)
      if (error?.code === 'auth/too-many-requests') {
        setErrorDialog({
          isError: true,
          message: error?.message
        })
      }
      if (error?.message === 'INVALID_IDENTIFIER') {
        setErrorDialog({
          isError: false
        })
      }
    }
  }

  const onDateChange = (day: number | null, month: number | null, year: number | null) => {
    if (day && month && year) {
      setDateOfBirth(new Date(year, month - 1, day))

      const dateString = `${year}-${month}-${day}`
      const dateParse = parse(dateString, 'yyyy-M-d', new Date())
      isValid(dateParse)

      if (isValid(dateParse)) {
        setValidInput({
          ...validInput,
          dob: new Date(year, month - 1, day)
        })
      }
    } else {
      setDateOfBirth(null)
    }
  }

  const onGenderChange = (gender: string) => {
    setValue('gender', gender)
    setLocalGender(gender)

    setValidInput({
      ...validInput,
      gender: gender
    })
  }

  useEffect(() => {
    const translatedErrors = Object.entries(errors).reduce(
      (acc, [key, errorVal]) => {
        if (errorVal && typeof errorVal === 'object' && errorVal.message) {
          acc[key] = {
            ...errorVal,
            message: t(errorVal.message, { lng: 'en' })
          }
        } else {
          acc[key] = errorVal
        }
        return acc
      },
      {} as typeof errors
    )
    setErrorEnglish(translatedErrors)
  }, [errors['emailOrPhone'], errors['passwordOrOtp'], t, i18n.language])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (JSON.stringify(!errorEnglish) !== '{}') {
      setIsGTMError(true)
      setGTMErrorMessage(errorEnglish)
    }
  }, [errors, errorEnglish, setIsGTMError, setGTMErrorMessage])

  return (
    <div className='signup'>
      <SignUpGTMEvents
        isGTMError={isGTMError}
        errorMessage={GTMErrorMessage}
        signInType={GTMSignInType}
        isSignUpConfirmStep1={isGTMSignUpConfirmStep1}
        isUserEnteredDataContinue={isUserEnteredDataContinue}
        enteredIncorrectPassword={enteredIncorrectPassword}
        isUserProfileUpdated={isUserProfileUpdated}
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <StepOneStyle container spacing={3} justifyContent='center' className='signup-stepOne'>
          <Grid item xs={12}>
            <InputField
              label={t('signup.create_account') || ''}
              name='emailOrPhone'
              register={register}
              errors={errorTranslation}
              defaultValue={emailOrPhoneVal || ''}
              clearErrors={clearErrors}
              setValue={(name, value) => {
                setValue(name, value)
                setValidInput({
                  ...validInput,
                  username: value
                })
              }}
              disabled={!!emailOrPhoneVal}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              label={t('signup.create_password') || ''}
              name='passwordOrOtp'
              type={showPassword ? 'text' : 'password'}
              register={register}
              errors={errorTranslation}
              defaultValue={passwordOrOtpVal || ''}
              clearErrors={clearErrors}
              setValue={(name, value) => {
                setValue(name, value)
                setValidInput({
                  ...validInput,
                  password: value
                })
              }}
              required={true}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    edge='end'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <DateDropdown onDateChange={onDateChange} isLabel />
              </Grid>
              <Grid item container xs={12} style={{ marginBottom: 20, paddingTop: 20 }}>
                <GenderGroup onGenderChange={onGenderChange} includeLgbtq isLabel />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  sx={{
                    '&.Mui-disabled': {
                      opacity: 0.5,
                      backgroundColor: '#8B8B8B',
                      color: 'white'
                    },
                    width: '100%', // Use 100% for responsiveness
                    borderRadius: '12px',
                    backgroundColor: '#F20B7E',
                    fontSize: '18px',
                    color: 'white',
                    fontFamily: 'Prompt',
                    fontWeight: '500',
                    lineHeight: '23px',
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: '#F20B7E'
                    },
                    padding: '16px 24px'
                  }}
                  variant='contained'
                  className={!isDisabled ? 'disabled' : ''}
                  endIcon={isLoading && <CircularProgress sx={{ color: '#fff' }} size={20} />}
                  disabled={!isDisabled}
                >
                  <Typography className='button_font_family'>
                    {t('signup.confirm') || ''}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid alignItems='center' justifyContent='center'>
            <div className='login-terms-conditions' key='terms_and_conditions'>
              <Trans
                i18nKey='login.policy_links'
                components={{
                  link_terms: (
                    <LinkText
                      defaultText='ข้อกำหนดและเงื่อนไข'
                      linkKey='terms'
                      textKey='legal.terms'
                      className='login-link-text'
                    />
                  ),
                  link_privacy: (
                    <LinkText
                      defaultText='นโยบายความเป็นส่วนตัว'
                      linkKey='privacy'
                      textKey='legal.privacy'
                      className='login-link-text'
                    />
                  ),
                  link_notice: (
                    <LinkText
                      defaultText=''
                      linkKey='privacy'
                      textKey='legal.notice'
                      className='login-link-text'
                    />
                  )
                }}
              />
            </div>
          </Grid>
        </StepOneStyle>
      </Form>

      {errorDialog.isError && (
        <ErrorDialog
          message={errorDialog.message}
          setClosed={() => {
            setIsDisabled(false)
            setErrorDialog({ isError: false, message: '' })
          }}
        />
      )}
    </div>
  )
}

export default StepOne
