import { z } from 'zod'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ErrorMessage } from '@hookform/error-message'
import { Flex, Input } from '@brightcove/studio-components'

import LanguageSelector from '../LanguageSelector/LanguageSelector'
import RedirectBack from '../Common/RedirectBack/RedirectBack'
import SignInGTMEvents from '../Common/GTM/SignInGTMEvents'
import { forgotPasswordStart, forgotPasswordSuccess } from '../../store/slices/forgotPasswordSlice'
import { useLazyUserCheckEmailQuery } from '../../store/services/user/userCheckApi'
import { RootState } from '../../store/index'
import './ForgotPassword.scss'
import { usePersistentPopState } from '../../hooks/usePersistentPopState'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import useAuth from '../../hooks/useAuth'
import { auth } from '../../firebase'
import { GIFImage, Image } from '../../assets/images'

interface IFormInputs {
  email: string
}

const schemaForgotPassword = z.string().email()

const ForgotPassword: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [errorTranslation, setErrorTranslation] = useState<any>({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { success } = useSelector((state: RootState) => state.forgotPassword)

  const { isMobile, isTablet } = useBreakPoints()
  const [isForgotPasswordFailed, setForgotPasswordFailed] = useState<boolean>(false)
  const [isResetButtonClicked, setResetButtonClicked] = useState<boolean>(false)
  const [userCheckEmail]: any = useLazyUserCheckEmailQuery()
  const location = useLocation()

  const [errorMessage, setErrorMessage] = useState<any>(null)

  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty }
  } = useForm<IFormInputs>()
  const { resetPassword } = useAuth()

  useEffect(() => {
    const translatedErrors = Object.entries(errors).reduce(
      (acc, [key, errorVal]) => {
        if (errorVal && typeof errorVal === 'object' && errorVal.message) {
          acc[key] = {
            ...errorVal,
            message: t(errorVal.message)
          }
        } else {
          acc[key] = errorVal
        }
        return acc
      },
      {} as typeof errors
    )
    setErrorTranslation(translatedErrors)
  }, [errors['email'], t, i18n.language])

  const onSubmit = async (data) => {
    setResetButtonClicked(true)
    try {
      if (schemaForgotPassword.safeParse(data.email).success) {
        const response = await userCheckEmail(data.email)
        if (response.status !== 'fulfilled') {
          throw new Error('forgot-password.invalid_email')
        }
      } else {
        throw new Error('forgot-password.invalid_email')
      }
    } catch (error: any) {
      setResetButtonClicked(false)
      setError('email', {
        type: 'manual',
        message: error.message
      })
      setErrorMessage(
        error.message.includes('phone')
          ? 'Phone number login disabled temporarily'
          : 'Invalid email'
      )
      setForgotPasswordFailed(true)
      return
    }
    dispatch(forgotPasswordStart())
    try {
      // Replace with Firebase's reset password functionality
      await resetPassword(data.email)
      // Once the email is sent successfully, handle the success response
      dispatch(forgotPasswordSuccess())
      reset()
      setForgotPasswordFailed(false)
      setResetButtonClicked(false)
    } catch (error) {
      setResetButtonClicked(false)
      setForgotPasswordFailed(true)
      setErrorMessage(error)
      dispatch(forgotPasswordSuccess())
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  useEffect(() => {
    if (success) {
      setTimeout(() => navigate(-1), 3000)
    }
  }, [success])

  const handleBackPressed = () => {
    if (location && location.pathname !== '/password-reset') return
    navigate('/login?step=2', {
      replace: true,
      state: { step: 2 }
    })
  }
  const clearGTMErrors = () => {
    setErrorMessage(null)
    setForgotPasswordFailed(false)
  }

  usePersistentPopState(handleBackPressed)

  return (
    <>
      <RedirectBack auth={auth} />
      <Box sx={{ display: { xs: 'block', lg: 'none', xl: 'none', md: 'none', sm: 'none' } }}>
        <div className='profile-mobile-edit'>
          <header>
            <>
              {isMobile && !success && (
                <div className='mobile-login-header'>
                  <Flex>
                    <ArrowBackIosIcon scale={4} />{' '}
                    <div onClick={handleBackPressed}>{t('login.back')}</div>
                  </Flex>
                  <LanguageSelector />
                </div>
              )}
            </>
          </header>
        </div>
      </Box>
      <div className='forgot-password'>
        <Flex
          flexDirection='column'
          alignItems='center'
          className='forgot-password-content'
          style={{ width: isMobile || isTablet ? '286px' : '350px' }}
        >
          <Flex flexDirection='column' alignItems='center'>
            <NavLink className='' to='/'>
              <img className='login-logo' src={Image.One31Logo} alt='OneD logo' />
            </NavLink>
            <span className='login-header'>{t('login.header')}</span>
          </Flex>
          {success ? (
            <div>
              <div
                className='forgot-password-success'
                style={{
                  backgroundImage: `url(${GIFImage.SuccessTickGif})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '328px',
                  height: '347px',
                  justifyContent: 'flex-end'
                }}
              >
                {/* <h1 className='forgot-password-title'>
                  {t('forgot-password.forgot_password') || ''}
                </h1> */}
                {/* <div className='forgot-password-sub-header'>
                  <p>Please enter the email or phone number linked to your account.</p>
                </div> */}
                {/* <div className='header-slider'>
                  <div className='gray-slider'></div>
                  <div className='pink-slider'></div>
                </div> */}
                <Flex>{/* <img src={GIFImage.SuccessTickGif} alt='check circle' /> */}</Flex>

                <p className='email-sent-text'>{t('forgot-password.email_sent') || ''}</p>
                <p className='please-check-email'>
                  {t('forgot-password.please_check_email') || ''}
                </p>
                {/* <Button type='button' onClick={handleResetState} className='back-button'>
                  {t('forgot-password.back') || ''}
                </Button> */}
              </div>
            </div>
          ) : (
            <div>
              <h1 className='forgot-password-title'>
                {t('forgot-password.forgot_password') || ''}
              </h1>
              <div className='forgot-password-sub-header'>
                <p>
                  {t('forgot-password.sub_header') || ''}
                  {/* <br /> */}
                  {t('forgot-password.sub_header2') || ''}
                </p>
              </div>
              {/* <div className='header-slider'>
                <div className='pink-slider'></div>
                <div className='gray-slider'></div>
              </div> */}
              <form className='forgot-password-form' onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          className={
                            Object.values(errors).length !== 0
                              ? 'error-password-input-field'
                              : 'password-input-field'
                          }
                          placeholder={t('forgot-password.email_or_phone_number') || ''}
                          {...field}
                        />
                        <ErrorMessage
                          errors={errorTranslation}
                          name='email'
                          as={<span className='error-message' />}
                        />
                        <div className='profile-footer'>
                          {/* {isMobile && (
                            <Button
                              type='button'
                              onClick={handleResetState}
                              className='back-button'
                            >
                              {t('forgot-password.back') || ''}
                            </Button>
                          )} */}
                          <Button
                            className={
                              !isDirty || field.value === ''
                                ? 'confirm-button disabled'
                                : 'confirm-button'
                            }
                            type='submit'
                            disabled={!isDirty || field.value === ''}
                            endIcon={
                              isResetButtonClicked && (
                                <CircularProgress sx={{ color: '#fff' }} size={20} />
                              )
                            }
                          >
                            {t('forgot-password.confirm') || ''}
                          </Button>
                        </div>
                      </>
                    )}
                    defaultValue=''
                  />
                </div>
              </form>
            </div>
          )}
        </Flex>
      </div>
      <SignInGTMEvents
        clearErrorsCb={clearGTMErrors}
        errorMessage={errorMessage}
        isForgotPasswordFailed={isForgotPasswordFailed}
      />
    </>
  )
}

export default ForgotPassword
