import { useEffect } from 'react'

import PopStateSingleton, { PopStateCallback } from '../services/PopStateSingleton'

export function usePersistentPopState(callback: PopStateCallback): void {
  useEffect(() => {
    const singleton = PopStateSingleton.getInstance()
    singleton.subscribe(callback)
    return () => {
      //   singleton.unsubscribe()
    }
  }, [callback])
}
