import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { Flex } from '@brightcove/studio-components'

import SignUpGTMEvents from '../Common/GTM/SignUpGTMEvents'
import { isEmptyObject } from '../../utils/utils'
import { getStorage, isLocalStorageSupported } from '../../utils/localStorageSupport'
import { setStep } from '../../store/slices/signupSlice'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { RootState } from '../../store'
import { GIFImage } from '../../assets/images'

const StepThree: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentLocation } = useSelector((state: RootState) => state.menu)
  const [isGTMSignUpSuccessful, setIsGTMSignUpSuccessful] = useState<boolean>(false)
  const [getProfileData] = useLazyGetUserProfileQuery()
  const storage = getStorage()

  useEffect(() => {
    document.body.className = 'body-white'
    return () => {
      document.body.className = ''
    }
  })

  const navigate = useNavigate()
  const routeChange = () => {
    setIsGTMSignUpSuccessful(true)
    // const path = `/`
    setTimeout(() => {
      const subscriptionStatus = checkSubscription()
      subscriptionStatus ? navigate(currentLocation as string) : navigate('/subscription')
      // navigate(currentLocation as string)
      dispatch(setStep(1))
      !isLocalStorageSupported()
        ? sessionStorage?.setItem('shouldNavigateBack', 'true')
        : storage.setItem('shouldNavigateBack', 'true')
    }, 0)
  }

  const checkSubscription = () => {
    getProfileData().then((profileResponse) => {
      if (profileResponse?.data?.subscription?.subscription_status === 'active') {
        return true
      } else if (
        profileResponse?.data?.subscription?.subscription_status === 'inactive' ||
        isEmptyObject(profileResponse?.data?.subscription)
      ) {
        return false
      }
    })
    return false
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      routeChange()
    }, 3000)
    return () => clearTimeout(redirectTimeout)
  }, [])

  return (
    <Flex alignItems='center' justifyContent='center' className='step3_confirmation'>
      <Grid container direction='column' justifyContent='center' style={{ alignItems: 'center' }}>
        <Grid item sx={{ height: '20px' }}></Grid>
        <Grid
          item
          sx={{
            backgroundImage: `url(${GIFImage.SuccessTickGif})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '284px',
            height: '347px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end'
          }}
        >
          <h3 className='confirm-header-signup'>{t('signup.signUp_completed') || ''}</h3>
        </Grid>
        <SignUpGTMEvents isSignUpSuccessful={isGTMSignUpSuccessful} />
      </Grid>
    </Flex>
  )
}

export default StepThree
